<template>
    <default-layout>
        <white-loader :loading="loading"/>
        <minimalist-breadcrumb title="Complementa tu visita" style="margin-bottom: 15px;"/>

        <div style="min-height: 500px;" v-if="loading">

        </div>

        <mdb-container>
            <div class="mobile-cards-container" v-if="experiences.length">
                <recommendations-card v-for="(experiencia, index) of experiences" 
                    v-bind:key="index"
                    :title="experiencia.nombre"
                    :showDefault="false"
                    :text="$t('default.CARDS_PLACE', {'msg':experiencia.place})"
                    :price-description="St('default.CARDS.START_FROM', {msg: `${ parseFloat(experiencia?.precioOrientativo)?.toLocaleString( 'es-ES', { style: 'currency', currency: 'EUR' } ) }`})"
                    :image-url="`${imagenesUrl}/actividades/${experiencia.oldId}/${experiencia.banner}`"
                    :showBuyButton="true"
                    @goTo="goToEntrada( { entradaId: experiencia.experienciaId } )"
                    @buy="() => goToBuy( experiencia.experienciaId )"
                    >

                    <font-awesome-icon icon="vest" transform="shrink-7" v-if="experiencia.type.includes('adventure')"/>
                    <font-awesome-icon icon="utensils" transform="shrink-7" v-if="experiencia.type.includes('food')" class="mx-1"/>
                    <font-awesome-icon icon="leaf" transform="shrink-7" v-if="experiencia.type.includes('nature')"/>
                    <font-awesome-icon icon="landmark" transform="shrink-7" v-if="experiencia.type.includes('cultural')"/>


                </recommendations-card>
            </div>

            <div v-else>
                <h5>NO SE ENCONTRARON SUGERENCIAS DISPONIBLES</h5>
            </div>
        </mdb-container>

    </default-layout>
</template>

<script>
import ExperiencesServices from '../../services/ApiExperiencias.js';
import RecommendationsCard from '../../components/RecommendationsCard';
export default {
    components:{
        RecommendationsCard
    },
    data(){
        return{
            loading: true,
            experiences: [
                { nombre: 'Tour por Belmonte', place: 'Belmonte, Cuenca', precioOrientativo: 30, experienciaId: '12333vdsahc1vcca2', oldId: 13213, banner: '152831-belmonte_aventura.webp', type: ['adventure', 'cultural']},
                { nombre: 'Clase ecuestre', place: 'Peracense, Teruel', precioOrientativo: 50, experienciaId: '12333vdsahc1vcca3', oldId: 69, banner: '1556-ecuestre_4.webp', type: ['nature', 'cultural']},
                { nombre: 'Recorrido gastronómico', place: 'Peracense, Teruel', precioOrientativo: 40, experienciaId: '12333vdsahc1vcca3', oldId: 72, banner: '1568-jamon_6.webp', type: ['food', 'cultural']},
                { nombre: 'Observación de aves', place: 'Villacañas, Toledo', precioOrientativo: 20, experienciaId: '12333vdsahc1vcca5', oldId: 13320, banner: 'observacindeavesenZEPAManchaNorte_DSCN4342.webp', type: ['nature']},
            ],
            imagenesUrl: process.env.VUE_APP_IMAGES_URL
        }
    },
    methods:{
        encodeUrl(name = '') {
            let rawName = name.replaceAll(',', '');
            rawName = rawName.replaceAll('.', '');

            let sanitizedName = rawName.normalize("NFD").replace(/[\u0300-\u036f]|[^0-9a-zA-Z]+[\u0020]/g, '')
            let sanitizedNameLowercase = sanitizedName.toLowerCase().replace(/[\u0020]/g, '-');

            return sanitizedNameLowercase
        },
        async getExperiences(){
            const _experiences = await ExperiencesServices.findExternalExperiences();
            // this.experiences = _experiences;
        },
        goToEntrada( event ){

            const encodedMonumentName = encodeURI( event.monumentoUrl);
            if (encodedMonumentName === 'castillo-de-san-pedro-de-jaca-(ciudadela)') {
                window.open(process.env.VUE_APP_JACA_URL);
            }
            else {
                this.$router.push('/castillos/tarifas/' + encodedMonumentName + '/' + event.entradaId)
            }
        },
        goToBuy( visitaId ){
            this.$router.push(`/castillos/reservar/idvisita:${visitaId}`)
        }
    },
    async created(){
        // Esperamos a obtener las experiencias disponibles
        try{
            await this.getExperiences();
        }
        catch( err ){
            console.log( err );
        }
        this.loading = false;
    }
}
</script>

<style scoped>
.mobile-cards-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 15px;
    column-gap: 15px;
    margin-bottom: 15px;
}

</style>