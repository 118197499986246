var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "default-layout",
    [
      _c("white-loader", { attrs: { loading: _vm.loading } }),
      _c("minimalist-breadcrumb", {
        staticStyle: { "margin-bottom": "15px" },
        attrs: { title: "Complementa tu visita" },
      }),
      _vm.loading
        ? _c("div", { staticStyle: { "min-height": "500px" } })
        : _vm._e(),
      _c("mdb-container", [
        _vm.experiences.length
          ? _c(
              "div",
              { staticClass: "mobile-cards-container" },
              _vm._l(_vm.experiences, function (experiencia, index) {
                return _c(
                  "recommendations-card",
                  {
                    key: index,
                    attrs: {
                      title: experiencia.nombre,
                      showDefault: false,
                      text: _vm.$t("default.CARDS_PLACE", {
                        msg: experiencia.place,
                      }),
                      "price-description": _vm.St("default.CARDS.START_FROM", {
                        msg: `${parseFloat(
                          experiencia?.precioOrientativo
                        )?.toLocaleString("es-ES", {
                          style: "currency",
                          currency: "EUR",
                        })}`,
                      }),
                      "image-url": `${_vm.imagenesUrl}/actividades/${experiencia.oldId}/${experiencia.banner}`,
                      showBuyButton: true,
                    },
                    on: {
                      goTo: function ($event) {
                        return _vm.goToEntrada({
                          entradaId: experiencia.experienciaId,
                        })
                      },
                      buy: () => _vm.goToBuy(experiencia.experienciaId),
                    },
                  },
                  [
                    experiencia.type.includes("adventure")
                      ? _c("font-awesome-icon", {
                          attrs: { icon: "vest", transform: "shrink-7" },
                        })
                      : _vm._e(),
                    experiencia.type.includes("food")
                      ? _c("font-awesome-icon", {
                          staticClass: "mx-1",
                          attrs: { icon: "utensils", transform: "shrink-7" },
                        })
                      : _vm._e(),
                    experiencia.type.includes("nature")
                      ? _c("font-awesome-icon", {
                          attrs: { icon: "leaf", transform: "shrink-7" },
                        })
                      : _vm._e(),
                    experiencia.type.includes("cultural")
                      ? _c("font-awesome-icon", {
                          attrs: { icon: "landmark", transform: "shrink-7" },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              }),
              1
            )
          : _c("div", [
              _c("h5", [_vm._v("NO SE ENCONTRARON SUGERENCIAS DISPONIBLES")]),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }