
import http from "../http-common";

class ExperienciesService{
    async findExternalExperiences(){
        const result = await http.get('/third-party-experiences');
        return result.data;
    }

}

export default new ExperienciesService();